import BaseService from "../Base";
import ApiUrls from "../ApiUrl";
import { CampaignProps } from "types/users/Campaign";
import { FileUpload, ParamType } from "types/Others";
import axios from "axios";


export default class CampaignService extends BaseService {
  async getCampaignNames(params: ParamType) {
    try {
      const response = await this.post(`${ApiUrls.campaign}/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getCampaigns(params={}) {
    try {
      const response = await this.post(`${ApiUrls.subCampaign}/list`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async createCampaign(params={}) {
    try {
      const response = await this.post(`${ApiUrls.subCampaign}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async updateCampaign(params={}) {
    try {
      const response = await this.post(`${ApiUrls.subCampaign}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async updateCampaignName(params={}) {
    try {
      const response = await this.post(`${ApiUrls.campaign}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async createPressRelease(params: any) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getAllSpecializations(params: any) {
    try {
      const response = await this.post(`${ApiUrls.specialization}/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async updatePressReleaseContent(params: any) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/updateContent`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async updatePressRelease(params: CampaignProps.PressReleaseFormParam) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/update`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getTopicsSpecilizations() {
    try {
      const response = await this.post(`${ApiUrls.specializationTopics}/list`, {});
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async createPRByFile(formData: FormData) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/create-pr-by-file`, formData);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getPressRelease(params: ParamType) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getWhatsappPressRelease(params: ParamType) {
    try {
      const response = await this.post(`${ApiUrls.whatsappPressRelease}/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async updatePressReleaseEdits(params: any) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/updateReactionStatus`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async assignEditor(params = {}){
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/assign-editor`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async updateKeywords(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/generate-article`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getComments(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.prComment}/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async createComments(params = {}) {
    try {
      const response = await this.post(`${ApiUrls.prComment}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getCampaignType(params={}) {
    try {
      const response = await this.post(`${ApiUrls.campaignType}/list`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getCampaignMedium(params={}) {
    try {
      const response = await this.post(`${ApiUrls.campaignMedium}/list`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getCampaignSupport(params={}) {
    try {
      const response = await this.post(`${ApiUrls.campaignSupport}/list`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getWordCountRange(params={}) {
    try {
      const response = await this.post(`${ApiUrls.wordCountRange}/list`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getLanguage(params={}) {
    try {
      const response = await this.post(`${ApiUrls.lan}/list`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  async getAIHeadline(params={}) {
    try {
      const response = await this.post(`${ApiUrls.generateHeadlines}`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //here
  

  async getModels(params:any={}){
    try{
      params["signal"] = this.newAbortSignal(this.abortTime);
      const config = this.setHeaderFromParams({});
      const response = await axios.post(`${process.env.REACT_APP_AI_API_BASE_URL}${ApiUrls.generateModels}`, params);
      // const response = await this.post(`${ApiUrls.generateModels}`,params)
      return response.data;
    }catch(error){
      throw(error)
    }
  }

  async createCampaignPublish(params: CampaignProps.PublishRequestProps) {
    try {
      const response = await this.post(`${ApiUrls.campaignPublish}/create`, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async uploadMultipleFiles(params: FormData) {
    try {
      const response = await this.post(ApiUrls.mediaURL, params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async getUserPromotion(param = {}) {
    try {
      const response = await this.post(`${ApiUrls.userPromotion}/list`, param);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }

  async getPRHistory(params: CampaignProps.PRHistoryProps) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/version/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async getPRHistoryDetail(params: CampaignProps.PRHistoryDetailProps) {
    try {
      const response = await this.post(`${ApiUrls.pressRelease}/version/detail`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async coinsTransactionList(params?: {userId: number}) {
    try {
      const response = await this.post(`${ApiUrls.coinsTransaction}/list`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
  async coinsTransactionCreate(params: any) {
    try {
      const response = await this.post(`${ApiUrls.coinsTransaction}/create`, params);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
}
