import assets, { AddCircle, Clock, Close, List, List2, Logout, profileIcon, UserProfile } from "assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./styles.scss";
import { PermissionType, RouteType } from "routes/config";
import userRoutes, { agencyRoutes, superAdminRoutes } from "routes/appRoutes";
import { useAppDispatch, useTypedSelector } from "redux/store";
import {
  logoutStore,
  selectDashboardSidebarClickCount,
  selectIsAgencyRole,
  selectIsMarketerRole,
  selectIsMobileView,
  selectIsShowSidebar,
  selectIsSuperAdminRole,
  selectPermissions,
  updateDashboardSidebarClickCount,
  updateIsShowSidebar,
} from "redux/features/appStateSlice";
import {
  getCampaignMediumStore,
  getCampaignSupportStore,
  getCampaignTypeStore,
  resetCampaignFormState,
  resetCampaignOprationState,
  restartContentDetailsState
} from "redux/features/campaignOprationalSlice";
import { Dropdown } from "react-bootstrap";
import React,{ useState, useRef, useEffect, useMemo } from "react";
import Confirmation from "./confirmation";
import { resetBussinessList } from "../../redux/features/bussinessListSlice";
import { resetCampaignList } from "../../redux/features/campaignSlice";
import { resetNotification } from "../../redux/features/notificationSlice";
import { resetPR } from "../../redux/features/pressReleaseSlice";
import { resetUserList } from "../../redux/features/userSlice";

const CustomDropdown = ({ isSuperAdminRole, onClickProfile, setConfirmationShow }:{
  isSuperAdminRole:any 
  onClickProfile:any
   setConfirmationShow:any
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event:any) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef?.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
    {isOpen && (
        <div className="dropDownMenu" ref={dropdownRef}>
            {!isSuperAdminRole && (
              <div
                className="dropDownItem"
                onClick={()=>onClickProfile()}
              >
                <img src={UserProfile} alt="UserProfile" className="menu-icon"  />
                <span>View Profile</span>
              </div>
            )}

            <div
                className="dropDownItem"
              onClick={()=>{
                setConfirmationShow(true)
              }}
            >
              <img src={Logout} alt="Logout" className="menu-icon" />
              <span>Logout</span>
            </div>
        </div>
      )}
    <div className="dropDown" >
      <div onClick={toggleDropdown}>
        <img src={profileIcon} alt="User" className="icon-circle" />
      </div>
    </div>
    </>
    
  );
};


const Sidebar = () => {
  const isSuperAdminRole = useTypedSelector(selectIsSuperAdminRole);
  const isAgencyRole = useTypedSelector(selectIsAgencyRole);
  const isMarketerRole = useTypedSelector(selectIsMarketerRole);
  const count = useTypedSelector(selectDashboardSidebarClickCount);
  const isShowSidebar = useTypedSelector(selectIsShowSidebar);
  const isMobileView = useTypedSelector(selectIsMobileView);
  const permissions = useTypedSelector(selectPermissions);
  const [confirmationShow, setConfirmationShow] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  
  const onClickNav = (index = 0) => {
    if (index === 0)
      dispatch(updateDashboardSidebarClickCount(Number(count) + 1));
    if(location?.pathname?.includes("campaign/create") || location?.pathname?.includes("campaign/update")) {
      dispatch(resetCampaignFormState());
      dispatch(resetCampaignOprationState());
      dispatch(restartContentDetailsState({}));
      dispatch(getCampaignTypeStore({}));
      dispatch(getCampaignMediumStore({}));
      dispatch(getCampaignSupportStore({}));
    }
    setSidebar(!isShowSidebar);
  };

  const setSidebar = (show = false) => {
    dispatch(updateIsShowSidebar({show}));
  }

  const reloadPage = (path: string = "") => {
    if(!path) return;
    return location?.pathname?.includes("campaign/update") && path?.includes("campaign/create");
  }

  const renderSideRoute = (routes: RouteType[] = []) => {
    if (!routes || !routes.length) return "";
    return routes.map((route: RouteType, index: number) => (
      <li
        key={route.path}
        className={`${!isSuperAdminRole ? "client_common" : ""} ${
          !!location?.pathname?.includes(route.path)
            ? !!isSuperAdminRole
              ? "active-adminSidebar"
              : "active-clientSidebar"
            : !isSuperAdminRole
            ? "client_li"
            : "admin_li"
        }`}
      >
        <Link
          className={!!isSuperAdminRole ? "admin_content" : "client_content"}
          to={route.path}
          onClick={() => onClickNav(index)}
          reloadDocument={reloadPage(route.path)}
        >
          <span
            className={
              !isSuperAdminRole ? "client_route-img" : "admin_route-img"
            }
          >
            {route.sidebarProps?.img}
          </span>
          {route.sidebarProps?.displayText}
        </Link>
      </li>
    ));
  };

  const getAdminUserSidebar = (routesArr: RouteType[] = [], permissionArr: PermissionType[] = []) => {
    return routesArr?.filter((route: RouteType) => {
      return permissionArr?.some((permission: PermissionType) => (permission?.module === route?.state && permission?.is_show))
    })
  }
  const onClickProfile = () => {
    navigate("/account-settings");
  };
 const userToggle = React.forwardRef<
    HTMLImageElement,
    {
      children: React.ReactNode;
      onClick: (e: React.MouseEvent<HTMLImageElement>) => void;
    }
  >(({ children, onClick }, ref) => (
    <>
        <img
          className="icon-circle"
          src={profileIcon}
          ref={ref}
          alt="Profile Img"
          // width="30"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        />
    </>
  ));

  const cleanAllState = () => {
      dispatch(resetBussinessList());
      dispatch(resetCampaignOprationState());
      dispatch(resetCampaignList());
      dispatch(resetNotification());
      dispatch(resetPR());
      dispatch(resetUserList());
      dispatch(resetNotification());
    };
  

  const onClickLogout = () => {
      dispatch(logoutStore());
      cleanAllState();
      navigate("/", { replace: true });
    };
useEffect(()=>{
  console.log(confirmationShow)
},[confirmationShow])
  const showConfirmation = useMemo(() => {
    if (!confirmationShow) return;
    return (
      <Confirmation
        show={confirmationShow}
        onHide={() => {
          setConfirmationShow(false);
        }}
        onConfirmation={() => {
          if (confirmationShow) onClickLogout();

        }}
        title={confirmationShow ? "Logout Confirmation" : "Delete Notification"}
        description={`Are you sure you want to ${
          confirmationShow ? "logout" : "delete this notification"
        }?`}
        actionBtnName="Yes"
        actionBtnColor="white"
        actionBtnBgColor="#00b8c2"
      />
    );
  }, [confirmationShow]);
  
  
  return (
    <>
    {
      !!isSuperAdminRole && 
      <div className={`sidebar ${!!isSuperAdminRole ? "roleOne" : "roleTwo"} ${(isShowSidebar && isMobileView) ? 'roleTwo-left0' : isMobileView ? 'roleTwo-left-m-100' : ''}`}>
      {isMobileView && (
        <Link to="#" className="closeIcon"> 
          <img 
            src={Close}
            alt="Close"
            onClick={() => setSidebar(!isShowSidebar)}
            width={24}
          />
        </Link>
      )}
      
      <ul className={!isSuperAdminRole ? 'client_logo-ui' : ""}>
        <li className={!isSuperAdminRole ? "client_logo-li" : "admin_li"}>
          <Link to="/">
            <img
              src={
                !!isSuperAdminRole
                  ? assets.images.grayLogo
                  : assets.images.shotLogo
              }
              alt="Logo"
              width=""
              className={
                !!isSuperAdminRole ? "logo-image" : "client_logo-image"
              }
            />
          </Link>
        </li>
        {renderSideRoute(isSuperAdminRole ? getAdminUserSidebar(superAdminRoutes, permissions) : (isAgencyRole || isMarketerRole) ? agencyRoutes : userRoutes)}
      </ul>
    </div>
    }
    {
      !isSuperAdminRole &&
      <div
      className={`sidebar1`}
      >
        <div className="upperSection">
        <Link to="/">
            <img
              src={
                !!isSuperAdminRole
                  ? assets.images.grayLogo
                  : assets.images.shotLogo
              }
              alt="Logo"
              width=""
              className={
                "client_logo-image"
              }
            />
            <div className="borderBottom"></div>
          </Link>
          
          <img src={AddCircle} alt="Campaigns"
          className="icon-circle"
          onClick={()=>{
            navigate("/manage-content/add-new-manage-content")
          }}
          />
          <img src={List2}
              className="icon-circle"
              onClick={()=>{
                navigate("/manage-content")
              }}
              /> 
        </div>
        <div className="lowerSection">
              <img src={Clock}
              className="icon-circle"
              />  
               
              <div>
              <img src={assets.images.whatsapp1}
              className="icon-circle"
              />
            <div className="borderBottom"></div>
              </div>
              <CustomDropdown
                isSuperAdminRole={isSuperAdminRole}
                onClickProfile={onClickProfile}
                setConfirmationShow={(val:any)=>{
                  setConfirmationShow(val)
                }}
              />
              
          
        </div>
      </div>
    }
    {showConfirmation}
    </>
   
  );
};

export default Sidebar;
